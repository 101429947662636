import React, {useEffect, useState} from 'react'
import {navigate, Link} from 'gatsby'
import sessionManager from '../util/sessionManager'
import socketManager from '../util/socketManager'
import classNames from "classnames";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import * as buttonStyles from "../styles/button.module.css";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

export default function Mentors() {
  const [global, setGlobal] = useState(sessionManager.getState())
  const dispatch = sessionManager.dispatch


  useEffect(function () {
    return sessionManager.subscribe(function () {
      setGlobal(sessionManager.getState())
    })
  }, [])


  if (!global.current_user_vuid) {
    navigate('/mentors')
    return null
  }


  return (
    <main>
      <title>Mentors</title>

      <NavBar global={global} dispatch={dispatch} />
      <div className="page-heading">
        <Container>
          <h1>Mentor's Corner</h1>
        </Container>
      </div>
      <Container>
        <Row>
          <Col md={{ span: 7 }}>
            <h3>CE-Oh! Course Material</h3>
            <Accordion id="accordionCoursesceoh">
              <Accordion.Item eventKey="01">
                <Accordion.Header>
                  01: Introduction to Entrepreneurship
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Learn about and compare real entrepreneurs &amp; innovators;
                    and learn how products &amp; services are developed in
                    response to customer needs.
                  </p>
                  <p className="h6">Downloads</p>
                  <ButtonGroup size="sm">
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-01-slides.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Slides
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-01-handout.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Handout
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-01-terms-and-definitions.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Terms & Definitions
                    </Button>
                    <Button
                      target='_blank'
                      type="button"
                      href='../modules/module-01-lesson-plan.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Lesson Plan
                    </Button>

                  </ButtonGroup>
                  <hr /><p className="h6">Additional Resources</p>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-01-business-model-canvas-workbook.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Business Model Canvas Workbook
                  </Button>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-01-blake-mycoskie-biography.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Blake Mycoskie Biography
                  </Button>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-01-sba-faq.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    SBA FAQ
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="02">
                <Accordion.Header>
                  02: Problem Solving and Social Responsibility
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Identify ways to solve social problems; learn the purpose of
                    mission and vision statements; and provide examples of
                    socially responsible business practices.
                  </p>
                  <p className="h6">Downloads</p>
                  <ButtonGroup size="sm">
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-02-slides.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Slides
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-02-handout.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Handout
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-02-terms-and-definitions.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Terms & Definitions
                    </Button>
                    <Button
                      target='_blank'
                      type="button"
                      href='../modules/module-02-lesson-plan.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Lesson Plan
                    </Button>
                  </ButtonGroup>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="03">
                <Accordion.Header>
                  03: Generating Business Ideas and Creative Thinking
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Learn about the entrepreneurial mindset; identify creative
                    business ideation; engage in creative thinking; and learn
                    the purpose of the business model canvas.
                  </p>
                  <p className="h6">Downloads</p>
                  <ButtonGroup size="sm">
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-03-slides.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Slides
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-03-handout.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Handout
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-03-terms-and-definitions.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Terms & Definitions
                    </Button>
                    <Button
                      target='_blank'
                      type="button"
                      href='../modules/module-03-lesson-plan.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Lesson Plan
                    </Button>
                  </ButtonGroup>
                  <hr /><p className="h6">Additional Resources</p>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-03-brainstorming-and-mind-mapping.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Brainstorming and Mind Mapping
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="04">
                <Accordion.Header>04: Market Research</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Learn the importance of planning and using market research
                    methodologies; the concept of Lean Startup Methodology;
                    explore market research resources; understand primary and
                    secondary data; refine business ideas based on research and
                    applying research cycles.
                  </p>
                  <p className="h6">Downloads</p>
                  <ButtonGroup size="sm">
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-04-slides.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Slides
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-04-handout.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Handout
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-04-terms-and-definitions.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Terms & Definitions
                    </Button>
                    <Button
                      target='_blank'
                      type="button"
                      href='../modules/module-04-lesson-plan.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Lesson Plan
                    </Button>
                  </ButtonGroup>
                  <hr /><p className="h6">Additional Resources</p>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-04-useful-links.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Useful Links
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="05">
                <Accordion.Header>05: Economics</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Focusing on the U.S. economy, learn about types of economic
                    systems; identify the role of the entrepreneur in the
                    economy; learn about suppliers and consumers and their
                    relationship; and learn the concept of supply and demand.
                  </p>
                  <p className="h6">Downloads</p>
                  <ButtonGroup size="sm">
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-05-slides.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Slides
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-05-handout.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Handout
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-05-terms-and-definitions.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Terms & Definitions
                    </Button>
                    <Button
                      target='_blank'
                      type="button"
                      href='../modules/module-05-lesson-plan.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Lesson Plan
                    </Button>
                  </ButtonGroup>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="06">
                <Accordion.Header>06: Competition</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Understand different types of competition and how it impacts
                    pricing; identify different types of competitors; explore
                    ways for businesses to remain competitive; and learn how to
                    gather competitive intelligence and create competitive
                    advantages.
                  </p>
                  <p className="h6">Downloads</p>
                  <ButtonGroup size="sm">
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-06-slides.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Slides
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-06-handout.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Handout
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-06-terms-and-definitions.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Terms & Definitions
                    </Button>
                    <Button
                      target='_blank'
                      type="button"
                      href='../modules/module-06-lesson-plan.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Lesson Plan
                    </Button>
                  </ButtonGroup>
                  <hr /><p className="h6">Additional Resources</p>

                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-06-sample-competitive-matrix.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Sample Competitive Matrix
                  </Button>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-06-eou-sample.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    EOU sample
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="07">
                <Accordion.Header>07: Value Proposition</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Identify and describe value proposition; differentiate
                    between benefits and features; and identify ways to promote
                    value.
                  </p>
                  <p className="h6">Downloads</p>
                  <ButtonGroup size="sm">
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-07-slides.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Slides
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-07-handout.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Handout
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-07-terms-and-definitions.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Terms & Definitions
                    </Button>
                    <Button
                      target='_blank'
                      type="button"
                      href='../modules/module-07-lesson-plan.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Lesson Plan
                    </Button>
                  </ButtonGroup>
                  <hr /><p className="h6">Additional Resources</p>

                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-07-constructing-a-problem-statement.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Constructing a Problem Statement
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="08">
                <Accordion.Header>08: Business Expenses</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Examine common business expense categories; understand
                    contribution margins; determine profit and loss; apply
                    break-even analysis; and learn ways to manage ongoing
                    business expenses.
                  </p>
                  <p className="h6">Downloads</p>
                  <ButtonGroup size="sm">
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-08-slides.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Slides
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-08-handout.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Handout
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-08-terms-and-definitions.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Terms & Definitions
                    </Button>
                    <Button
                      target='_blank'
                      type="button"
                      href='../modules/module-08-lesson-plan.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Lesson Plan
                    </Button>
                  </ButtonGroup>
                  <hr /><p className="h6">Additional Resources</p>

                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-08-sample-cash-flow-statement.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Sample Cash Flow Statement
                  </Button>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-08-sample-contribution-margin-worksheets.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Sample Contribution Margin Worksheets
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="09">
                <Accordion.Header>09: Revenue Streams</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Identify potential revenue streams; understand sales
                    forecasting; and learn the parts and functions of income
                    statements.
                  </p>
                  <p className="h6">Downloads</p>
                  <ButtonGroup size="sm">
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-09-slides.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Slides
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-09-handout.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Handout
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-09-terms-and-definitions.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Terms & Definitions
                    </Button>
                    <Button
                      target='_blank'
                      type="button"
                      href='../modules/module-09-lesson-plan.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Lesson Plan
                    </Button>
                  </ButtonGroup>
                  <hr />
                  <p className='h6'>Additional Resources</p>

                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-09-income-statement.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Income Statement
                  </Button>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-09-household-budgeting-worksheet.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Household Budgeting Worksheet
                  </Button>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-09-swot-analysis-worksheet.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    SWOT Analysis Worksheet
                  </Button>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-09-Simple-Balance-Sheet.xlsx'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Simple Balance Sheet
                  </Button>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-09-sales-forecasting-tools.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Sales Forecasting Tools
                  </Button>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-09-Sample-Profit-and-Loss-Template.xlsx'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Sample Profit and Loss Template
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="10">
                <Accordion.Header>10: Financing Your Business</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Estimate the start-up investment needed; learn about ROI and
                    how to calculate it; and find ways to obtain investments.
                  </p>
                  <p className="h6">Downloads</p>
                  <ButtonGroup size="sm">
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-10-slides.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Slides
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-10-handout.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Handout
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-10-terms-and-definitions.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Terms & Definitions
                    </Button>
                    <Button
                      target='_blank'
                      type="button"
                      href='../modules/module-10-lesson-plan.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Lesson Plan
                    </Button>
                  </ButtonGroup>
                  <hr />
                  <p className="h6">Additional Resources</p>

                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-10-contracts-and-agreements-best-practices.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Contracts and Agreements Best Practices
                  </Button>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-10-venture-capital-list.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Venture Capital List
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="11">
                <Accordion.Header>11: Business Financials</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Learn how to utilize and analyze income statements; build a
                    balance sheet; identify the functions of various financial
                    statements; and learn how to calculate financial ratios.
                  </p>
                  <p className="h6">Downloads</p>
                  <ButtonGroup size="sm">
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-11-slides.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Slides
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-11-handout.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Handout
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-11-terms-and-definitions.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Terms & Definitions
                    </Button>
                    <Button
                      target='_blank'
                      type="button"
                      href='../modules/module-11-lesson-plan.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Lesson Plan
                    </Button>
                  </ButtonGroup>
                  <hr />
                  <p className="h6">Additional Resources</p>

                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-11-balance-sheet.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Balance Sheet
                  </Button>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-11-sample-cash-flow-statement.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Sample Cash Flow Statement
                  </Button>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-11-same-size-balance-sheet.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Same Size Balance Sheet
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="12">
                <Accordion.Header>12: Managing Your Business</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Identify management functions and leaderships styles; create
                    organizational structures; learn planning strategies; and
                    learn how to create a healthy business environment.
                  </p>
                  <p className="h6">Downloads</p>
                  <ButtonGroup size="sm">
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-12-slides.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Slides
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-12-handout.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Handout
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-12-terms-and-definitions.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Terms & Definitions
                    </Button>
                    <Button
                      target='_blank'
                      type="button"
                      href='../modules/module-12-lesson-plan.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Lesson Plan
                    </Button>
                  </ButtonGroup>
                  <hr />
                  <p className="h6">Additional Resources</p>

                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-12-sample-organizational-charts.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Sample Organizational Charts
                  </Button>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="13">
                <Accordion.Header>13: Growth and Exit Plans</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Understand business growth and product life cycles; learn
                    how to value a business; identify possible exit strategies;
                    and understand investments and how money grows.
                  </p>
                  <p className="h6">Downloads</p>
                  <ButtonGroup size="sm">
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-13-slides.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Slides
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-13-handout.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Handout
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-13-terms-and-definitions.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Terms & Definitions
                    </Button>
                    <Button
                      target='_blank'
                      type="button"
                      href='../modules/module-13-lesson-plan.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Lesson Plan
                    </Button>
                  </ButtonGroup>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="14">
                <Accordion.Header>14: Legal Structures</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Identify different types of legal structures and their
                    strengths and weaknesses.
                  </p>
                  <p className="h6">Downloads</p>
                  <ButtonGroup size="sm">
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-14-slides.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Slides
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-14-handout.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Handout
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-14-terms-and-definitions.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Terms & Definitions
                    </Button>
                    <Button
                      target='_blank'
                      type="button"
                      href='../modules/module-14-lesson-plan.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Lesson Plan
                    </Button>
                  </ButtonGroup>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="15">
                <Accordion.Header>
                  15: Constructing a Business Plan
                </Accordion.Header>
                <Accordion.Body>
                  <p>
                    Apply what has been learned to create a business model
                    canvas; create an elevator pitch; and learn how to build a
                    pitch deck.
                  </p>
                  <p className="h6">Downloads</p>
                  <ButtonGroup size="sm">
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-15-slides.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Slides
                    </Button>
                    <Button
                      target='_blank'
                      type='button'
                      href='../modules/module-15-handout.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Handout
                    </Button>
                    <Button
                      target='_blank'
                      type="button"
                      href='../modules/module-15-lesson-plan.pdf'
                      className={classNames(buttonStyles.button)}
                    >
                      Lesson Plan
                    </Button>
                  </ButtonGroup>
                  <hr />
                  <p className="h6">Additional Resources</p>

                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-15-elevator-speech-template-and-sample.pdf'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    Elevator Speech Template and Sample
                  </Button>
                  <Button
                    target='_blank'
                    type="button"
                    href='../modules/module-15-PPT-Presentation-Examples-TOMS.pptx'
                    size="sm"
                    className={classNames(buttonStyles.buttonSecondary)}
                  >
                    PPT Presentation Examples – TOMS
                  </Button>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
          <Col>
          <h3>Zoom Meetings</h3>
          <Card
              className='text-center'
          >
              <Card.Body>

                <Link to='/zoom'>
                  <Button type='button'
                    className={classNames(
                      buttonStyles.button,
                      buttonStyles.buttonPrimary,
                    )}
                    size="lg"
                  >
                    Schedule A Zoom Meeting
                  </Button>
                </Link>
              </Card.Body>

          </Card>

          </Col>
        </Row>
      </Container>

      <div className='footer'>
        <Footer />
      </div>
    </main>
  );
}
